<script>
export default {
  name: 'AdvicaBookingMixins',
  computed: {
    productId() {
      return this.$route.params.id;
    },
  },
};
</script>
