<script>
import AdvicaBookingMixins from './AdvicaBookingMixins.vue';

export default {
  name: 'AdvicaDetailsMixins',
  mixins: [AdvicaBookingMixins],
  methods: {
    loadAdvicaDetails() {
      if (!this.hasDetails) {
        this.$store.dispatch('advica/loadDetails', {
          productId: this.productId,
        });
      }
    },
  },
  computed: {
    getBaseCurrency() {
      return this.advicaDetails.value.priceStartingAt.baseCurrency;
    },
    getStartingPrice() {
      return this.advicaDetails.value.priceStartingAt.value;
    },
    advicaDetails() {
      return this.$store.state['advica'].details;
    },
    hasDetails() {
      return this.$store.getters['advica/hasDetails'];
    },
    error() {
      if (!!this.advicaDetails) return this.advicaDetails.error;
      else return null;
    },
    isLoading() {
      return (
        (!!this.advicaDetails && this.advicaDetails.isLoading) ||
        this.error !== null
      );
    },
    detailsModel() {
      if (!!this.advicaDetails) return this.advicaDetails.value;
      else return null;
    },
  },
};
</script>
