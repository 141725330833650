<template>
  <v-container v-if="advicaPageFF" fluid class="pa-0">
    <vue-headful :title="pageTitle" />
    <router-view class="health-route-port pb-0"></router-view>
  </v-container>
</template>

<script>
import vueHeadful from 'vue-headful';
import AdvicaDetailsMixins from '@/modules/product/advica/mixins/AdvicaDetailsMixins';
import * as Sentry from '@sentry/vue';
export default {
  name: 'AdvicaPage',
  components: { vueHeadful },
  mixins: [AdvicaDetailsMixins],
  data() {
    return {
      advicaPageFF: false,
    };
  },
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      vm.advicaPageFF = await vm.$ldclient.variation(
        'advica-page',
        false,
        true
      );
      if (!vm.advicaPageFF) {
        next(from);
      }
    });
  },
  computed: {
    partnerName() {
      return this.$store.state.configs && this.$store.state.configs.title
        ? this.$store.state.configs.title
        : '';
    },
    pageTitle() {
      if (this.partnerName) {
        return this.$t('advica.page_title_with_partner_name', {
          partnerName: this.partnerName,
        });
      } else {
        return this.$t('advica.page_title_without_partner_name');
      }
    },
  },
  created() {
    Sentry.setTag('module', 'health');
    this.loadAdvicaDetails();
  },
  methods: {
    redirectToErrorPage() {
      const notFoundError = this.error && this.error.status >= 400;
      const internalServerError = this.error && this.error.status >= 500;

      if (internalServerError) this.$router.replace('/500');
      else if (notFoundError) this.$router.replace('/404');
    },
  },
  watch: {
    error(val) {
      if (!!val) {
        this.redirectToErrorPage();
      }
    },
  },
};
</script>

<style scoped lang="scss">
.health-route-port {
  &,
  div {
    z-index: 2;
  }
}
</style>
